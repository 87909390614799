@import "~@boomrank/react-components/src";

.beta {
  .dashboard .headline {
    background-image: None;
    @apply bg-br-red;
  }
}

.btn-blue:hover {
  @apply bg-br-blue-500;
}

.progressBarElement:nth-child(even) .elementWrapper{
  flex-direction: column-reverse;
}
.progressBarElement:nth-child(odd) .elementWrapper{
  flex-direction: column;
}
#progressBarCOST {
  top: 15px;
}

.progressBarMinValue:after {
  content: "["
}
.progressBarMaxValue:after {
  content: "]"
}